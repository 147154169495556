import React from "react"
import home from "../images/home.jpg"
import SEO from "../components/seo"
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <>
    <SEO
      title="404 Not Found"
      image={`https://www.ppdc.mx${home}`}
      description="La página que buscabas no existe"
      url="/404"
    />
    <div
      style={{
        height: "70vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "1.3em",
      }}
    >
      <h2>Esta página no existe o el contenido ha sido removido</h2>
      <br />
      <p>
        Para regresar al home puedes dar clic{" "}
        <Link paintDrip hex="#141A3A" to="/" title="home">
          Aquí
        </Link>
      </p>
    </div>
  </>
)

export default NotFoundPage
